import React from 'react';
import { useSelector } from 'react-redux';
import { Document, Page, pdfjs } from 'react-pdf';
import compose from 'lodash/fp/compose';
import ListDocumentLottie from 'lottie-react';
import { documentSlice } from 'rootStateHandler/selectors';
import animatedLoading from 'assets/lottie/admit-cat-loader.json';
import { withTranslation } from '../../../hoc';
import { editIcon, deleteIcon, viewIcon } from '../../../../libs/icon';
import EmptyState from 'components/common/EmptyState';
import './listDocuments.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// react-pdf__message react-pdf__message--error

const ListDocuments = (props) => {
  const {
    handleDisplayInformation,
    isSearching,
    document_id,
    handleDelete,
    handleEdit,
    handleViewDocument,
    tr,
  } = props;

  const { filtere_documents, loading, list_documents, search_result, isFiltering } =
    useSelector(documentSlice);

  const filteredDocuments = [...filtere_documents];

  const getFileExtension = (filename) => {
    const parts = filename.split('.');
    return parts.length > 1 ? parts.pop() : ''; // Return the last part as the file extension
  };

  const isImageFile = (filename) => {
    const fileExt = getFileExtension(filename);
    const validImageExts = ['gif', 'png', 'jpg', 'jpeg'];
    return validImageExts.includes(fileExt.toLowerCase());
  };

  const truncateString = (input_string, string_length) => {
    if (!input_string || typeof input_string !== 'string') {
      return '';
    }

    const truncated_string =
      input_string.length >= string_length
        ? `${input_string.substring(0, string_length)}...${getFileExtension(
            input_string,
          )}`
        : input_string;

    return truncated_string;
  };

  const documentPreviews = (
    <div>
      {Boolean(list_documents?.length === 0) ? (
        <div className="empty_state_container">
          <EmptyState text={tr('No document uploaded yet!')} hasButton={false} />
        </div>
      ) : Boolean(loading) ? (
        <div className="lottie_loader_document">
          <ListDocumentLottie loop autoplay animationData={animatedLoading} />
          <div className="loading_text">Loading...</div>
        </div>
      ) : (
        <div className="list_documents_container">
          {list_documents?.map((document) => (
            <div key={document?.id} className="document_preview_container">
              <div
                className="document_preview"
                aria-selected={document_id === document?.id}
                onClick={() => handleDisplayInformation(document?.id)}
              >
                {isImageFile(document?.filename) ? (
                  <img
                    src={document?.file || '/placeholder.svg'}
                    alt="document"
                    aria-label="document-image"
                    className="preview-content"
                  />
                ) : (
                  <div className="pdf-wrapper">
                    <Document file={document?.file}>
                      <Page
                        pageNumber={1}
                        width={62}
                        className="pdf-page"
                        renderTextLayer={false}
                        renderAnnotationLayer={false}
                      />
                    </Document>
                  </div>
                )}

                {Boolean(document?.id) && (
                  <div
                    className={document_id === document?.id ? 'popIcon' : 'hideIcon'}
                  >
                    <span onClick={() => handleEdit()}>{editIcon}</span>
                    <span>
                      <a
                        href={`https://download.admincat.net/media/documents/${document?.filename
                          .split(' ')
                          .join('_')}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {viewIcon}
                      </a>
                    </span>
                    <span onClick={() => handleDelete()}>{deleteIcon}</span>
                  </div>
                )}
              </div>

              <p
                className="documentTitle"
                style={{ color: 'var(--admincat-color-grey-2)' }}
              >
                {truncateString(document?.filename, 10)}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );

  const filteredDocumentsByCriteria = (
    <div>
      {Boolean(loading) ? (
        <div className="lottie_loader_document">
          <ListDocumentLottie loop autoplay animationData={animatedLoading} />

          <div className="loading_text">{tr('Loading...')}</div>
        </div>
      ) : Boolean(filteredDocuments?.length === 0 || undefined) ? (
        <div className="empty_state_container">
          <EmptyState text={tr('No result')} hasButton={false} />
        </div>
      ) : (
        <div className={'list_documents_container'}>
          {filteredDocuments?.map((document) => (
            <div key={document?.id} className={'document_preview_container'}>
              <div
                className={'document_preview'}
                aria-selected={document_id === document?.id ? true : false}
                onClick={() => handleDisplayInformation(document?.id)}
              >
                {Boolean(document?.snippet) ? (
                  <div className="snippet">
                    <img
                      src={document?.snippet}
                      height={118}
                      width={85}
                      alt="snippet"
                    />
                  </div>
                ) : (
                  <Document file={`${document?.file}`}>
                    <Page height={118} width={85} pageNumber={1} />
                  </Document>
                )}

                <div
                  className={document_id === document.id ? 'popIcon' : 'hideIcon'}
                >
                  <span onClick={() => handleEdit()}>{editIcon}</span>
                  <span onClick={() => handleViewDocument()}>{viewIcon}</span>
                  <span onClick={() => handleDelete()}>{deleteIcon}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );

  const documentSearchPreviews = (
    <div>
      {Boolean(loading) ? (
        <div className="lottie_loader_document">
          <ListDocumentLottie loop autoplay animationData={animatedLoading} />

          <div className="loading_text">{tr('Loading...')}</div>
        </div>
      ) : Boolean(search_result?.length === 0 ?? undefined) ? (
        <div className="empty_state_container">
          <EmptyState text={tr('No result was found...')} hasButton={false} />
        </div>
      ) : (
        <div className={'filtered_documents_container'}>
          <p>
            {tr('Results found')}: {search_result?.length} {tr('result(s)')}
          </p>

          {search_result?.map((document) => {
            return (
              <div key={document?.id} className={'filtered_documents_wrapper'}>
                <div
                  className={'filtered_document_preview'}
                  onClick={() => handleDisplayInformation(document.id)}
                >
                  {Boolean(document?.snippet) ? (
                    <div className="snippet">
                      <img
                        src={document?.snippet}
                        height={118}
                        width={85}
                        alt="snippet"
                      />
                    </div>
                  ) : (
                    <Document file={`${document?.file}`}>
                      <Page height={118} width={85} pageNumber={1} />
                    </Document>
                  )}
                </div>

                <div className={'filtered_preview_info'}>
                  <p>{truncateString(document?.filename, 30)}</p>
                  <p>{truncateString(document?.description, 30)}</p>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );

  return (
    <>
      {isFiltering && filteredDocumentsByCriteria}

      {!isSearching && !isFiltering && documentPreviews}

      {isSearching && <>{documentSearchPreviews}</>}
    </>
  );
};

export default compose(withTranslation)(ListDocuments);
