/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Validator from 'validatorjs';
import styled from 'styled-components';
import compose from 'lodash/fp/compose';
// import { useDataArea } from 'hooks/useDataArea';
import { useOrgIndicator } from 'hooks/useOrgIndicator';
import { useFavoriteOrganisation } from 'hooks/useFavoriteOrganisation';
import {
  getSingleContract,
} from 'actions';
import {
  withReligion,
  withHeightUnit,
  withTranslation,
  withPersonalInfo,
  withCustomRouter,
  withMeasurementUnit,
  withContract,
  withMsgInfo,
} from 'components/hoc';
import {
  Select,
  Input,
  InputDatePicker,
  Form,
  ButtonLink,
} from 'components/common/FormItems';
import { ORG_INDICATOR_TYPES, orgIndicatorOptions } from 'constants/index';
import { documentSlice } from 'rootStateHandler/selectors';
import { listDocumentsThunk } from 'components/Dashboard/Documents/documentStates/documentThunk';
import { isEmptyValue } from 'components/utils/checkValue';
import { excludeUnsetField } from 'components/utils/excludeUnsetField';
import axios from '../../../../../axios';
import API from 'utils/api';
import { getDataArea } from 'actions';


const Wrapper = styled.div``;

const StyledButtonLink = styled(ButtonLink)`
  span {
    text-transform: uppercase;
  }
`;

const yesOrNoOptions = [
  {
    id: 'true',
    long_description_eng: 'Yes',
    long_description_deu: 'Ja',
  },
  {
    id: 'false',
    long_description_eng: 'No',
    long_description_deu: 'Nein',
  },
];

const initialValues = {
  description: '',
  data_area: '',
  continuous: 'true',
  contractor: '',
  org_indicator: ORG_INDICATOR_TYPES.ORG,
  auto_renewal: '',
  run_time: '',
  run_time_unit: '',
  customer_id: '',
  contract_id: '',
  start_date: '',
  notice_period: '',
  notice_period_unit: '',
  renewal_period: '',
  renewal_period_unit: '',
  person: null,
  person_is: 'B',
  document: null,
};

const contractFormSchema = {
  description: {
    validation: 'required|min:3',
  },
  data_area: {
    validation: 'required',
  },
  continuous: {
    validation: 'required',
  },
  contractor: {
    validation: 'required',
  },
  org_indicator: {
    validation: 'required',
  },
  auto_renewal: {
    validation: [{ required_if: ['continuous', 'true'] }],
  },
  run_time: {
    validation: [{ required_if: ['auto_renewal', 'true'] }, 'numeric'],
  },
  run_time_unit: {
    validation: [{ required_if: ['auto_renewal', 'true'] }],
  },
  start_date: {
    validation: 'required',
  },
  notice_period: {
    validation: [{ required_if: ['continuous', 'true'] }, 'numeric'],
  },
  notice_period_unit: {
    validation: [{ required_if: ['continuous', 'true'] }],
  },
  renewal_period: {
    validation: [{ required_if: ['auto_renewal', 'true'] }, 'numeric'],
  },
  renewal_period_unit: {
    validation: [{ required_if: ['auto_renewal', 'true'] }],
  },
  person: {
    validation: 'required',
  },
  person_is: {
    validation: 'required',
  },
};

const ContractForm = (props) => {
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});

  const [values, setValues] = useState({ ...initialValues });
  const [created, setCreated] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  // const { dataArea, dataAreaOptions } = useDataArea();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { handleOrgIndicatorChange, organisationOrPersonOptions } = useOrgIndicator({
    defaultOrgIndicator: ORG_INDICATOR_TYPES.ORG,
  });

  const {
    tr,
    // contract,
    personalInfoOptions,
    timeMeasurementLocaleOptions,
    basePath,
    routeParams,
    // gotoDetailPath,
    createContract,
    updateContract,
    setMsgInfo,
    // getSingleContract,
    // contractInfoOptions,
  } = props;

  const history = useHistory();
 
  const { list_documents } = useSelector(documentSlice);

  const dispatch = useDispatch();
  const favoriteOrganisation = useFavoriteOrganisation();

  const dataAreaOptions = useSelector((state) => state.dataArea);

  useEffect(() => {
    dispatch(getDataArea());
  }, []);

  const handleChange = ({ target: { name, value } }) => {
    if (isSubmitting) return;
    setTouched((prevValues) => ({ ...prevValues, [name]: true }));
    setValues((prevValues) => ({ ...prevValues, [name]: value }));
    const validation = new Validator(
      { ...values, [name]: value },
      contractFormSchema,
    );
    if (validation.fails()) {
      setErrors(validation.errors);
      return;
    }
    setErrors(validation.errors);
  };

  const onSubmit = async (e) => {
    if (isSubmitting) return;
    let payload = excludeUnsetField(values);

    const fData = new FormData();
    Object.entries(payload).forEach(([key, value]) => {
      // if (key === 'document' && values[key]?.type === 'file') {
      if (key === 'document_link') {
        // exluding document and file if it's null and value is not empty, meaning no
        // change made to the field
        if (Boolean(value.length) && !isEmptyValue(value)) {
          fData.append(key, value);
        }
      } else {
        fData.append(key, value);
      }
    });

    try {
      setIsSubmitting(true);
      if (!values.id) {
        await createContract(fData);
        dispatch(
          setMsgInfo({
            success: true,
            msg: [`${tr('Contract created successfully, you can proceed to add contract items')}`],
          }),
        );
      } else {
        await updateContract(fData, values.id);
        dispatch(
          setMsgInfo({
            success: true,
            msg: [`${tr('Contract updated successfully')}`],
          }),
        );
      }
      history.push(basePath);
      window.scrollTo(0, 0);
    } catch (e) {
    } finally {
      setIsSubmitting(false);
    }
  };

  const documemtOptions = list_documents?.map((document) => ({
    id: document?.id,
    long_description_deu: document?.filename,
    long_description_eng: document?.filename,
  }));

  useEffect(() => {
    dispatch(listDocumentsThunk());
  }, [dispatch]);

  // useEffect(() => {
  //   setValues((currentValues) => ({
  //     ...currentValues,
  //     // data_area: dataArea,
  //   }));
  // }, [
  //   // dataArea,
  //   setValues,
  // ]);


  useEffect(() => {
    const { id: contractId } = routeParams;
    console.log(contractId)
    if (contractId) {
      try {
        setFormLoading(true);
        axios
          .get(`${API}/dashboard/contracts/${contractId}`)
          .then((response) => {
            const { data } = response;
            setValues(data);
            setFormLoading(false);
            setCreated(true);
          })
          .catch((e) => {
            setValues({});
            setFormLoading(false);
          });
      } catch (e) {
        setValues({});
        setFormLoading(false);
      }
    } else {
      setValues({});
      setFormLoading(false);
    }
  }, []);

  const moveToContractItems = () => {
    const pathArray = basePath.split('/');
    pathArray.pop();
    const pathway = `${pathArray.join('/')}/contract-item?q=${values?.id}`;
    return pathway;
  };


  return (

    <Wrapper className="form-wrapper">
      <div className="flex">
        <StyledButtonLink
          to={basePath}
          className="option-btn"
          icon="angle double left"
          name="All Entries"
        />
        {values?.id != null && values?.id != undefined && (
              <StyledButtonLink
                to={moveToContractItems()}
                className="option-btn"
                icon="angle double right"
                name="Go to contract items"
              />
            )}
        </div>
        <div className="row">
          <div
            className="col-7 ml-10 mt-30 container"
            style={{
              position: 'relative',
              background: 'var(--admincat-color-grey-1)',
            }}
          >
        <br/>

        <Form
          onSubmit={onSubmit}
          formData={values}
          validationSchema={contractFormSchema}
          isLoading={isSubmitting}
          loadingData={formLoading}
          title="Contract"
        >
          <div className="row ">
            <div className="col-md-12">
              <Input
                showRequiredAsterisk
                value={values.description}
                onChange={handleChange}
                name="description"
                label={tr('Description')}
                placeholder={tr('Description')}
              />
            </div>
          </div>
          <div className="row values-row">
            <div className="col-md-12">
              <Select
                showRequiredAsterisk
                label={tr('Data area')}
                optionDefaultValue={tr('Choose Data Area')}
                value={values.data_area}
                name="data_area"
                onChange={handleChange}
                // disabled
                localeOptions={dataAreaOptions}
              />
            </div>
          </div>
          <div className="row values-row justify-content-between">
            <div className="col-md-6">
              <Select
                showRequiredAsterisk
                label={tr('Continuous')}
                value={String(values.continuous)}
                name="continuous"
                localeOptions={yesOrNoOptions}
                onChange={handleChange}
                className="d-inline w-40"
                sort={false}
                optionDefaultValue="Choose Continuous"
              />
            </div>

            <div className="col-md-6">
              <Select
                showRequiredAsterisk
                label={tr('Contractor is Organisation or Person')}
                value={values.org_indicator}
                name="org_indicator"
                id="title"
                className="d-inline"
                localeOptions={orgIndicatorOptions}
                // onChange={handleChange}
                onChange={(e) => {
                  handleChange(e);
                  handleOrgIndicatorChange(e.target.value);
                }}
                sort={false}
                optionDefaultValue="Choose Org. or Person"
              />
            </div>
          </div>
        
          <div className="row values-row justify-content-between">
            <div className="col-md-12">
              <Select
                showRequiredAsterisk
                label={tr('Contractor')}
                optionDefaultValue={tr('Choose Contractor')}
                value={values?.contractor}
                name="contractor"
                localeOptions={organisationOrPersonOptions}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="row values-row justify-content-between" style={{marginTop:'5px'}}>
            <div className="col-md-6">
              <Select
                showRequiredAsterisk
                label={tr('Person')}
                optionDefaultValue="Choose Person"
                value={values.person}
                //className="w-80"
                name="person"
                options={personalInfoOptions}
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6">
              <Select
                showRequiredAsterisk
                label="Person Role"
                optionDefaultValue="Choose Person Role"
                value={values.person_is}
                name="person_is"
                className="w-40"
                options={[
                  {
                    id: 'B',
                    name: tr('Buyer'),
                  },
                  {
                    id: 'S',
                    name: tr('Seller'),
                  },
                ]}
                onChange={handleChange}
                sort={false}
              />
            </div>
          </div>
          <div className="row values-row justify-content-between">
            <div className="col-md-6">
              <Input
                label="Customer ID"
                placeholder="Customer ID"
                optionDefaultValue="Data area"
                value={values.customer_id}
                name="customer_id"
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6 pr-4">
              <Input
                label="Contract ID"
                placeholder="Contract ID"
                value={values.contract_id}
                name="contract_id"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row values-row justify-content-between">
            <div className="col-md-6">
              <InputDatePicker
                showRequiredAsterisk
                value={values.start_date}
                onChange={handleChange}
                name="start_date"
                type="date"
                placeholder=""
                label={tr('Start date')}
              />
            </div>
            <div className="col-md-6">
              <Select
                // showRequiredAsterisk={values.continuous === 'true'}
                label={tr('Automatic renewal after Run time')}
                optionDefaultValue='Choose Auto Renewal'
                value={String(values.auto_renewal)}
                name="auto_renewal"
                localeOptions={yesOrNoOptions}
                onChange={handleChange}
                sort={false}
              />
            </div>
          </div>
          <div className="row values-row justify-content-between">
            <div className="col-md-6">
              <Input
                showRequiredAsterisk={values.auto_renewal === 'true'}
                value={values.run_time}
                onChange={handleChange}
                name="run_time"
                placeholder={tr('Run time')}
                label={tr('Run time')}
              />
            </div>
            <div className="col-md-6">
              <Select
                showRequiredAsterisk={values.auto_renewal === 'true'}
                label={tr('Run time Unit')}
                value={values.run_time_unit}
                name="run_time_unit"
                optionDefaultValue={tr('Run time Unit')}
                localeOptions={timeMeasurementLocaleOptions}
                onChange={handleChange}
                deselectable
                sort={false}
              />
            </div>
          </div>
          <div className="row values-row justify-content-between">
            <div className="col-md-6">
              <Input
                showRequiredAsterisk={values.continuous === 'true'}
                value={values.notice_period}
                onChange={handleChange}
                name="notice_period"
                placeholder={tr('Notice Period')}
                label={tr('Notice period')}
              />
            </div>
            <div className="col-md-6">
              <Select
                showRequiredAsterisk={values.continuous === 'true'}
                label={tr('Notice period unit')}
                optionDefaultValue={tr('Notice Period')}
                value={values.notice_period_unit}
                name="notice_period_unit"
                localeOptions={timeMeasurementLocaleOptions}
                onChange={handleChange}
                deselectable
                sort={false}
              />
            </div>
          </div>
          <div className="row values-row justify-content-between">
            <div className="col-md-6">
              <Input
                showRequiredAsterisk={values.auto_renewal === 'true'}
                value={values.renewal_period}
                onChange={handleChange}
                name="renewal_period"
                placeholder={tr('Renewal Period')}
                label={tr('Renewal period')}
              />
            </div>
            <div className="col-md-6">
              <Select
                showRequiredAsterisk={values.auto_renewal === 'true'}
                label={tr('Renewal period unit')}
                optionDefaultValue={tr('Renewal Period')}
                value={values.renewal_period_unit}
                name="renewal_period_unit"
                localeOptions={timeMeasurementLocaleOptions}
                onChange={handleChange}
                deselectable
                sort={false}
              />
            </div>
          </div>
          <div className="row values-row justify-content-between">
            <div className="col-md-12">
              <Select
                label={tr('Add Document')}
                optionDefaultValue={tr('Choose Document')}
                value={values.document_link}
                name="document_link"
                id="filename"
                localeOptions={documemtOptions}
                onChange={handleChange}
              />
            </div>
          </div>

          {/* <FileInput
        onChange={handleChange}
        value={values.document}
        hasError="Error"
        name="document"
        label={tr('Document')}
      /> */}
        </Form>
      </div>
    </div>
    </Wrapper>
  );
};

export default compose(
  withReligion,
  withHeightUnit,
  withTranslation,
  withPersonalInfo,
  withCustomRouter,
  withMeasurementUnit,
  withContract,
  withMsgInfo,
)(ContractForm);
