import React from 'react';
import { useSelector } from 'react-redux';
import compose from 'lodash/fp/compose';
import Lottie from 'lottie-react';
import { withTranslation, withUserPreferences } from '../../../hoc';
import Icon from 'components/Icon';
import animatedLoading from 'assets/lottie/admit-cat-loader.json';
import { documentSlice } from 'rootStateHandler/selectors';
import './documentInfo.css';
import { convertFromBytes } from 'utils';

const DocumentInfo = (props) => {
  const { tr, trObj, fd } = props;
  const { documentDetails, isloadingDetails } = useSelector(documentSlice);
  const updatedAt = documentDetails && new Date(documentDetails.updated_at);
  const DOCUMENT_SIZE_IN_KB =
    documentDetails && documentDetails.document_size
      ? documentDetails.document_size / 1024
      : documentDetails && documentDetails.size / 1024;

  return (
    <>
      {Boolean(Object.keys(documentDetails)?.length !== 0) ? (
        Boolean(isloadingDetails === true) ? (
          <div className="lottie_loader">
            <Lottie loop autoplay animationData={animatedLoading} />

            <div className="loading_text">Loading...</div>
          </div>
        ) : (
          <>
            <table>
              <tbody>
                <tr>
                  <th>
                    {tr('Space')}: {documentDetails.space || 'N/A'}
                  </th>
                </tr>
                <tr>
                  <th>
                    {tr('File name')}: {documentDetails.filename || 'N/A'}
                  </th>
                </tr>
                <tr>
                  <th>
                    {tr('Issuing organization')}:{' '}
                    {documentDetails.issuing_org_detail !== null
                      ? documentDetails.issuing_org_detail['name'] || 'N/A'
                      : 'N/A'}
                  </th>
                </tr>
                <tr>
                  <th>
                    {tr('Description')}: {documentDetails.description || 'N/A'}
                  </th>
                </tr>
                <tr>
                  <th>
                    {tr('Data Area')}:{' '}
                    {trObj(documentDetails.main_area_detail) || 'N/A'}
                  </th>
                </tr>
                <tr>
                  <th>
                    {tr("Organisation's country")}:{' '}
                    {trObj(documentDetails.country_detail) || 'N/A'}
                  </th>
                </tr>
                <tr>
                  <th>
                    {tr("Organisation's state")}:{' '}
                    {trObj(documentDetails.state_detail) || 'N/A'}
                  </th>
                </tr>
                <tr>
                  <th>
                    {tr("Organisation's city")}:{' '}
                    {trObj(documentDetails.city_detail) || 'N/A'}
                  </th>
                </tr>
                <tr>
                  <th>
                    {tr('Document category')}:{' '}
                    {trObj(documentDetails.document_category_detail) || 'N/A'}
                  </th>
                </tr>
                <tr>
                  <th>
                    {tr('Document type')}:{' '}
                    {trObj(documentDetails?.document_type_detail) || 'N/A'}
                  </th>
                </tr>
                <tr>
                  <th>
                    {tr('Concerned Person')}:{' '}
                    {documentDetails?.concerned_person_detail?.first_name ||
                      'N/A' +
                        ' ' +
                        documentDetails?.concerned_person_detail?.last_name ||
                      'N/A'}
                  </th>
                </tr>
                <tr>
                  <th>
                    {tr('Language')}:{' '}
                    {trObj(documentDetails.language_detail) || 'N/A'}
                  </th>
                </tr>
                <tr>
                  <th>
                    {tr('Issuing Date')}: {fd(documentDetails.issuing_date) || 'N/A'}
                  </th>
                </tr>
                <tr>
                  <th>
                    {tr('Reference Year')}: {documentDetails.reference_year || 'N/A'}
                  </th>
                </tr>
                <tr>
                  <th>
                    {tr('Reference Month')}:{' '}
                    {documentDetails.reference_month || 'N/A'}
                  </th>
                </tr>
                <tr>
                  <th>
                    {tr('Valid From')}: {fd(documentDetails.valid_from) || 'N/A'}
                  </th>
                </tr>
                <tr>
                  <th>
                    {tr('Valid To')}: {fd(documentDetails.valid_to) || 'N/A'}
                  </th>
                </tr>
                <tr>
                  <th>
                    {tr('Last updated')}: {fd(updatedAt.toDateString()) || 'N/A'}
                  </th>
                </tr>
                <tr>
                  <th>
                    {tr('Document size')} :{' '}
                    {`${convertFromBytes(
                      documentDetails?.document_size || documentDetails?.size,
                    )}` ?? 'N/A'}
                    {/* {`${DOCUMENT_SIZE_IN_KB.toFixed(2)}KB` ?? 'N/A'} */}
                  </th>
                </tr>
              </tbody>
            </table>
          </>
        )
      ) : (
        <div className="infoEmptyState">
          <Icon icon={'multiDocIcon'} width={100} height={100} />

          <p className="infoEmptyStateText">{tr('No document selected')}</p>
        </div>
      )}
    </>
  );
};

export default compose(withTranslation, withUserPreferences)(DocumentInfo);
