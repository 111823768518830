/* eslint-disable */
import React from 'react';
import compose from 'lodash/fp/compose';
import {
  withTranslation,
  withPersonalInfo,
  withCustomRouter,
  withUserPreferences,
} from 'components/hoc';
import MyDataContentTemplate from 'components/Dashboard/MyData/common/MyDataContentTemplate';
import LottieLoader from 'components/common/LottieLoader';

const Person = (props) => {
  const { personalInfo, trObj, fd, fn, tr } = props;

  // define columns to filter on
  const filterableColumns = [
    {
      columnId: '_title',
      columnDataType: 'text',
    },
    {
      columnId: 'last_name',
      columnDataType: 'text',
    },
    {
      columnId: 'first_name',
      columnDataType: 'text',
    },
    {
      columnId: 'other_names',
      columnDataType: 'text',
    },
    {
      columnId: '_birth_country',
      columnDataType: 'text',
    },
    {
      columnId: '_date_of_birth',
      columnDataType: 'date',
    },
    {
      columnId: '_date_of_death',
      columnDataType: 'date',
    },
    {
      columnId: '_religion',
      columnDataType: 'text',
    },
    {
      columnId: 'birth_place',
      columnDataType: 'text',
    },
    {
      columnId: '_marit_st_last_change',
      columnDataType: 'date',
    },
    {
      columnId: '_sex',
      columnDataType: 'text',
    },
    {
      columnId: '_eye_color',
      columnDataType: 'text',
    },
    {
      columnId: '_marital_status',
      columnDataType: 'text',
      options: [],
    },
    {
      columnId: '_height_unit',
      columnDataType: 'text',
    },
    {
      columnId: '_height',
      columnDataType: 'text',
    },
  ];

  const mapFields = () => {
    return personalInfo.data.map((data) => {
      return {
        ...data,
        id: data.id,
        space_name: data.space,
        _title: trObj(data.title_detail),
        _eye_color: trObj(data.eye_color_detail),
        _religion: trObj(data.religion_detail),
        _sex: trObj(data.sex_detail),
        _marital_status: trObj(data.marital_status_detail),
        _height_unit: trObj(data.height_unit_detail),
        _birth_country: trObj(data.birth_country_detail),
        _ethnic_group: trObj(data.ethnic_group_detail),
        _date_of_birth: fd(data.date_of_birth),
        _date_of_death: fd(data.date_of_death),
        _marit_st_last_change: fd(data.marit_st_last_change),
        _height: fn(data.height),
        _person_picture: data.person_picture?.name,
      };
    });
  };

  const { deleteBulkPersonalInfo, loaded, gotoCreatePath } = props;

  if (!loaded) {
    return (
      <>
        <LottieLoader />
      </>
    );
  }

  return (
    <MyDataContentTemplate
      tableName="person"
      columns={[]}
      data={mapFields()}
      handleCreateForm={gotoCreatePath}
      bulkDelete={deleteBulkPersonalInfo}
      filterableColumns={filterableColumns}
    />
  );
};

export default compose(
  withTranslation,
  withPersonalInfo,
  withCustomRouter,
  withUserPreferences,
)(Person);
