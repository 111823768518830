import React, { useEffect } from 'react';
import compose from 'lodash/fp/compose';
import styled from 'styled-components';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './style.css';
import { useLocation, Link } from 'react-router-dom';
import {
  withMail,
  withAccount,
  withTranslation,
  withUserDefaultSpace,
  withFormValidation,
} from '../../../hoc';
import { FormValidationContext } from '../../../hoc/Preload/FormValidation';
import {
  FormModal,
  Form,
  Input,
  // FileInput,
  Button,
  // TextArea,
  // TextEditor,
  RichTextEditor,
  // Editor,
  ButtonLink,
} from '../../../common/FormItems';
import { FileEarmark, XCircleFill } from 'react-bootstrap-icons';
import { setMsgInfo } from '../../../../actions';
import { useDispatch } from 'react-redux';
import { Icon, Popup } from 'semantic-ui-react';

const Wrapper = styled.div`
  .form-wrapper {
    padding: 20px;
  }

  .container {
    background: var(--admincat-color-grey-1);
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
  }
`;

const Flex = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  justify-content: ${({ justifyContent }) => justifyContent || ''};
  align-items: ${({ alignItems }) => alignItems || ''};
  flex-wrap: wrap;
`;

const FileAvatar = styled.a`
  margin: 5px 5px;
  font-style: italic;
  font-size: 0.9rem;
  align-items: center;
  display: block;
  // color: var(--admincat-color-grey-11);
  background: #fff;

  &:last-child {
    margin-top: 0;
  }
`;

Flex.displayName = 'Flex';

const InputSection = styled(Flex)`
  margin: 2px 0;
`;

const StyledButtonLink = styled(ButtonLink)`
  span {
    text-transform: uppercase;
  }
`;

const InputWrapper = styled(Flex)`
  margin-right: 5px;
  justify-content: space-between;
  max-width: 100%;
  align-items: center;
  flex-wrap: wrap;
  > div {
    flex: 1;
  }
  textarea,
  input {
    min-width: 200px;
  }
`;

const Label = styled.label`
  color: var(--admincat-dark-color);
  font-size: 1rem;
  font-weight: 600;
  margin-right: 10px;
  align-self: start;
  width: 75px;
`;

const StyledButton = styled(Button)`
  && {
    // color: var(--admincat-dark-color) !important;
    text-transform: capitalize;
    width: 8.0625rem;
    height: 2.5rem;
    font-weight: 500;
    border-radius: 7px;
    transition: all ease-in-out 300ms;
    background: #4c81be;
    border: 1px solid #1d579b;
    box-sizing: border-box;
    font-style: normal;
    font-size: 1rem;
    line-height: 1rem;
    color: #ffffff;
    cursor: pointer;
    margin-right: 1rem;
  }
`;

const mailFormSchema = {
  from_mail: {
    validation: 'required|email',
    message: {
      email: "Sender's email is invalid",
      required: "Sender's email is required",
    },
  },
  to_mail: {
    validation: 'required',
    message: {
      required: "Reciever's email is required",
    },
  },
  subject: {
    validation: 'required|min:3',
  },
  message: {
    validation: 'required|min:3',
    Link,
  },
};

// const MAX_SIZE_OF_ATTACHMENTS = 8485760; // this is the file size for attachments the django mail client can handle without crashing
const MAX_SIZE_SINGLE_DOCUMENT = 10485760; // Maximum size for a single document attachment (10MB)
const MAX_SIZE_OF_ATTACHMENTS = 26214400; // this is the file size for attachments the django mail client can handle without crashing (25MB)

const getFields = (formData) => {
  return [
    {
      type: 'email',
      name: 'from_mail',
      label: 'From',
      placeholder: 'Your email',
      disabled: true,
    },
    {
      type: 'text',
      name: 'to_mail',
      label: 'To',
      placeholder: 'example1@domain.com; example2@domain.com; example3@domain.com',
    },
    {
      type: 'text',
      name: 'subject',
      label: 'Subject',
      placeholder: 'Subject',
    },
    {
      type: 'textarea',
      name: 'message',
      label: 'Message',
      placeholder: 'Your message...',
    },
  ].map((data) => ({
    ...formData,
    ...data,
    value: formData[data.name],
  }));
};

const InputFieldType = {
  email: Input,
  text: Input,
  textarea: RichTextEditor,
};

const Fields = (props) =>
  getFields({ ...props }).map(({ type, label, tr, ...rest }) => {
    const InputField = InputFieldType[type];

    return (
      <InputWrapper key={label}>
        <Label>{tr(label)}:</Label>
        <InputField type={type} {...rest} />
      </InputWrapper>
    );
  });

const Compose = (props) => {
  const {
    openComposeForm,
    toggleComposeForm,
    onMailChange: onChange,
    mailFormData,
    sendMail,
    sendMailLoading,
    account: {
      user: { email: personalEmail },
    },
    userDefaultSpace: { space_detail },
    tr,
    basePath,
  } = props;
  const location = useLocation();
  const { selectedMail, type, previousPath } = location.state;
  const dispatch = useDispatch();

  const spaceEmail = space_detail && space_detail.email;

  const { validateForm } = React.useContext(FormValidationContext);

  const from_mail = spaceEmail || personalEmail;

  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null);
  const [uploadedFiles, setUploadedFiles] = React.useState([]);

  // Add a new state to track file validation
  const [fileValidationError, setFileValidationError] = React.useState(false);

  const handleFileUploadClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleFileChange = (event) => {
    const chosenFiles = Array.prototype.slice.call(event.target.files);
    handleUploadFiles(chosenFiles);
  };

  const validateFiles = (files) => {
    // Convert bytes to MB for readable error messages
    const maxSingleSizeMB = Math.floor(MAX_SIZE_SINGLE_DOCUMENT / (1024 * 1024));
    const maxTotalSizeMB = Math.floor(MAX_SIZE_OF_ATTACHMENTS / (1024 * 1024));

    // Check individual file sizes first
    const oversizedFiles = files.filter(
      (file) => file.size > MAX_SIZE_SINGLE_DOCUMENT,
    );
    if (oversizedFiles.length > 0) {
      setFileValidationError(true);
      dispatch(
        setMsgInfo({
          success: false,
          msg: [
            `${tr(
              'one or more files exceed the maximum allowed file size of',
            )} ${maxSingleSizeMB} ${tr('MB')}`,
          ],
        }),
      );
      return false;
    }

    // Calculate total size
    const totalSize = files.reduce((sum, file) => sum + file.size, 0);

    // Check total size
    if (totalSize > MAX_SIZE_OF_ATTACHMENTS) {
      setFileValidationError(true);
      dispatch(
        setMsgInfo({
          success: false,
          msg: [
            `${tr(
              'the total size of the selected files exceeds the maximum allowed size of',
            )} ${maxTotalSizeMB} ${tr('MB')}`,
          ],
        }),
      );
      return false;
    }

    // All validations passed
    setFileValidationError(false);
    return true;
  };

  const handleUploadFiles = (files) => {
    const uploaded = [...uploadedFiles];

    // Add new files if they don't already exist
    files.forEach((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
      }
    });

    // Always update the uploaded files array
    setUploadedFiles(uploaded);
    onChange({ target: { name: 'attachments', value: files } });

    // Validate after adding - this will set the error state and disable submit if needed
    if (validateFiles(uploaded)) {
      dispatch(
        setMsgInfo({
          success: true,
          msg: [`${tr('Files have been appended.')}`],
        }),
      );
    }
  };

  const removeFile = (fileName) => {
    const files = uploadedFiles.filter((file) => file.name != fileName);

    validateFiles(files);
    setUploadedFiles(files);
    onChange({ target: { name: 'attachments', value: files } });

    if (!fileValidationError) {
      dispatch(
        setMsgInfo({
          success: true,
          msg: [`${tr('File removed successfully.')}`],
        }),
      );
    }
  };

  useEffect(() => {
    if (spaceEmail) {
      onChange({ target: { name: 'from_mail', value: from_mail } });
    }
    if (type == 'fwd') {
      onChange({
        target: { name: 'subject', value: `Fwd: ${selectedMail?.subject}` },
      });
    }
    if (type == 'reply') {
      onChange({
        target: { name: 'subject', value: `Re: ${selectedMail?.subject}` },
      });
      onChange({ target: { name: 'to_mail', value: selectedMail?.from_mail } });
    }
    return () => null;
  }, [spaceEmail, mailFormData.from_mail]);

  return (
    <Wrapper>
      <div className="flex">
        <StyledButtonLink
          to={`${basePath}/mail/inbox`}
          className="option-btn"
          icon="angle double left"
          name="All Entries"
        />
      </div>
      <div className="row" style={{ margin: '20px 0' }}>
        <div className="col-md-8">
          <Form
            title="Compose Mail"
            open={openComposeForm}
            toggleForm={() => toggleComposeForm(null)}
            formData={mailFormData}
            validationSchema={mailFormSchema}
            renderActionButtons={() => null}
          >
            <Flex justifyContent="flex-end"></Flex>
            <InputSection flexDirection="column">
              <Fields
                {...mailFormData}
                selectedMail={selectedMail}
                tr={tr}
                onChange={onChange}
                key={openComposeForm}
              />
              <Flex justifyContent="flex-end" alignItems="flex-end">
                <Popup
                  content={tr('Attach documents')}
                  size="mini"
                  trigger={
                    <Icon
                      name="attach"
                      onClick={handleFileUploadClick}
                      size="large"
                      style={{ marginRight: '1%', cursor: 'pointer' }}
                    />
                  }
                />
                <StyledButton
                  onClick={validateForm(sendMail)}
                  name="Send"
                  isLoading={sendMailLoading}
                  disabled={fileValidationError}
                  style={{
                    opacity: fileValidationError ? 0.5 : 1,
                    cursor: fileValidationError ? 'not-allowed' : 'pointer',
                  }}
                />
                <input
                  type="file"
                  ref={hiddenFileInput}
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                  multiple
                  accept=".pdf,.doc,.docx,.txt,.rtf,.jpg,.jpeg,.png,.gif,.xlsx,.xls,.csv,.ppt,.pptx"
                />
              </Flex>
            </InputSection>
          </Form>
        </div>

        {uploadedFiles.length > 0 && (
          <div className="col-md-4">
            <Form
              title={`${tr('Attachments')} (${uploadedFiles.length})`}
              renderActionButtons={null}
            >
              <Flex justifyContent="flex-start" flexDirection="column">
                {uploadedFiles.map((data) => (
                  <FileAvatar key={data?.name}>
                    <Icon name="attach" /> {data?.name}{' '}
                    <Popup
                      content={tr('Remove this file')}
                      size="mini"
                      trigger={
                        <Icon
                          name="times"
                          onClick={() => removeFile(data?.name)}
                          circular
                          inverted
                          color="red"
                          size="small"
                          style={{ cursor: 'pointer' }}
                        />
                      }
                    />
                  </FileAvatar>
                ))}
              </Flex>
            </Form>
          </div>
        )}
      </div>
    </Wrapper>
  );
};

export default compose(
  withMail,
  withAccount,
  withTranslation,
  withUserDefaultSpace,
  withFormValidation,
)(Compose);
