import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { convertFromBytes } from 'utils';
import styled from 'styled-components/macro';
import { getAllForm, getDocuments, getPersonalInfo } from 'actions';

const Small = styled.small`
  color: red;
  font-size: 20px;
`;

const withFileSize = (WrappedComponent) => (props) => {
  const dispatch = useDispatch();
  const [file, setFile] = React.useState(false);

  // Document
  const document = useSelector((state) => state.documents.data);

  const documentfiltered = document.filter(function (value) {
    return value?.document_size != null || value?.size != null;
  });

  const DocumentSize = documentfiltered.reduce(function (index, value) {
    return index + value?.document_size || 0;
  }, 0);

  // Personal
  const personal = useSelector((state) => state.personalInfo.data);
  const Personalfiltered = personal.filter(function (value) {
    return value.person_picture.size != null;
  });
  const PersonalSize = Personalfiltered.reduce(function (index, value) {
    return index + value.person_picture.size;
  }, 0);

  const TotalFileSize = DocumentSize + PersonalSize;
  const fileSizeinDetails = convertFromBytes(TotalFileSize);

  React.useEffect(() => {
    dispatch(getDocuments());
    dispatch(getPersonalInfo());
    dispatch(getAllForm());
  }, [dispatch]);

  let msg;
  const Message = file
    ? (msg = (
        <Small>
          You have reach your File limit. You cannot upload any file or send emails.
        </Small>
      ))
    : null;

  return (
    <WrappedComponent
      {...props}
      totalFileSize={fileSizeinDetails}
      isFile={file}
      msg={Message}
    />
  );
};

export default withFileSize;
