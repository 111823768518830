import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import compose from 'lodash/fp/compose';
import { useDispatch } from 'react-redux';
import ContentTemplate from 'components/common/ContentTemplate';
import { withUserLayoutChoice, withTranslation } from 'components/hoc';
import { DnDPortal } from 'components/common/FormItems/DnDPortal';
import FilterModal, {
  FilterButtonWithBadge,
  parseDate,
} from '../../common/MyDataContentTemplate/FilterModal';

// const ButtonWrapper = styled.div`
//   margin-top: -6px;
// `;

const ButtonWrapper = styled.div`
  margin-top: 10px;
`;

const HeaderWrapper = styled.div`
  margin-top: 5px;
  disply: flex;
  justify-content: space-between;
  align-items: center;
  width: 92%;
`;

export const StyledContentTemplate = styled(ContentTemplate)`
  div.table-responsive {
    height: auto;
    min-height: 70vh;
    max-height: 70vh;
    overflow-y: auto;
    background: var(--admincat-color-grey-1);
    width: auto;
    max-width: 94%;

    @media screen and (max-width: 768px) {
      min-height: 70vh;
      max-height: 70vh;
      overflow-y: auto;
      width: auto;
      background: var(--admincat-color-grey-1);
      max-width: 87%;
    }
    @media screen and (min-width: 1200px) {
      min-height: 70vh;
      max-height: 70vh;
      overflow-y: auto;
      background: var(--admincat-color-grey-1);
      width: auto;
      max-width: 96%;
    }
  }
`;

const ViewDataContent = (props) => {
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [filters, setFilters] = useState([
    {
      id: Date.now(),
      column: '',
      condition: '',
      value: '',
      additionalValues: { start: [], end: [] },
    },
  ]);
  const [filteredData, setFilteredData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setFilteredData(props.data);
  }, [props.data]);

  const {
    getLayoutColumnProps,
    tableName,
    frontendTableName: fTableName,
    backendTableName: bTableName,
    columns: defaultColumns,
    tr,
    msg,
    data,
    filterableColumns,
    ...rest
  } = props;

  const frontendTableName = fTableName || tableName;
  const backendTableName = bTableName || tableName;

  const { columns, layoutColumns } = getLayoutColumnProps(
    frontendTableName,
    backendTableName,
    defaultColumns,
  );

  const toggleFilterModal = () => {
    setOpenFilterModal(!openFilterModal);
  };

  const onDataFiltered = (data) => {
    setFilteredData(data);
  };

  const renderFilters = (filterableColumns, columns, onDataFiltered) => {
    return (
      <FilterModal
        tr={tr}
        filters={filters}
        setFilters={setFilters}
        columns={columns}
        data={props.data}
        filterableColumns={filterableColumns}
        onClose={toggleFilterModal}
        onDataFiltered={onDataFiltered}
      />
    );
  };

  return (
    <React.Fragment>
      {msg}
      <HeaderWrapper className="flex">
        <ButtonWrapper className="flex" style={{ marginBottom: '15px' }}>
          <DnDPortal tableName={frontendTableName} columns={layoutColumns} />
          {filterableColumns.length > 0 && (
            <FilterButtonWithBadge
              filters={filters}
              onClick={toggleFilterModal}
              tr={tr}
            />
          )}
        </ButtonWrapper>
      </HeaderWrapper>
      <StyledContentTemplate {...rest} columns={columns} data={filteredData} />

      {openFilterModal && renderFilters(filterableColumns, columns, onDataFiltered)}
    </React.Fragment>
  );
};

ViewDataContent.defaultProps = {
  frontendTableName: null,
  backendTableName: null,
  columns: [],
};

export default compose(withUserLayoutChoice, withTranslation)(ViewDataContent);
