/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import compose from 'lodash/fp/compose';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Icon, Divider, Segment } from 'semantic-ui-react';
import { excludeUnsetField } from 'components/utils/excludeUnsetField';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { isEmptyValue } from 'components/utils';
import {
  withPersonalInfo,
  withOrgFunctionAssignment,
  withTranslation,
  withDiseaseTypes,
  withVaccinationHistory,
  withCustomRouter,
  withMsgInfo,
} from 'components/hoc';
import {
  Input,
  Select,
  InputDatePicker,
  ColumnalSelect,
  Form,
  FormModal,
  ButtonLink,
} from 'components/common/FormItems';
import { StyledSquareCheckbox as SquareCheckbox } from './styled';

const Wrapper = styled.div``;

const StyledButtonLink = styled(ButtonLink)`
  span {
    text-transform: uppercase;
  }
`;

const StyledDiseaseList = styled(FormModal)`
  &&& {
    z-index: 111;
  }
`;

const personLabels = [
  {
    first_name: 'First Name',
  },
  {
    last_name: 'Last Name',
  },
];

const vaccinationHistoryFormSchema = {
  vaccination_date: {
    validation: 'required',
  },
  person: {
    validation: 'required',
  },
  org: {
    validation: 'required',
  },
  // trade_name: {
  //   validation: "required",
  // },
  // batch_no: {
  //   validation: "required",
  // },
};

const FlexReverse = styled.div`
  display: flex;
`;

const DiseaseProtectionList = (props) => {
  const {
    handleChange,
    toggleDiseaseListForm,
    diseaseListForm,
    disease_protection_list,
    diseaseProtectionList,
  } = props;

  const { trObj } = props;

  const onChange = (value) => {
    let values = [...disease_protection_list];
    if (disease_protection_list.includes(value)) {
      values = disease_protection_list.filter((v) => v !== value);
    } else {
      values.push(value);
    }
    handleChange({
      target: {
        name: 'disease_protection_list',
        value: values,
      },
    });
  };

  return (
    <StyledDiseaseList
      title="Disease List"
      open={diseaseListForm}
      toggleForm={() => {
        onChange({
          target: {
            name: 'disease_protection_list',
            value: [{}],
          },
        });
        toggleDiseaseListForm();
      }}
      onSubmit={() => null}
      renderActionButtons={() => null}
    >
      {diseaseProtectionList.map((values, index) => {
        return (
          <Segment key={index}>
            <FlexReverse>
              <SquareCheckbox
                name={values.id}
                value={disease_protection_list.includes(values.id)}
                onChange={() => onChange(values.id)}
              />
              <div>{trObj(values)}</div>
            </FlexReverse>
          </Segment>
        );
      })}
      <Button onClick={toggleDiseaseListForm} type="button">
        {props.tr('Close')}
      </Button>
    </StyledDiseaseList>
  );
};

class VaccinationHistoryForm extends React.Component {
  state = {
    error: null,
    submittingForm: false,
    loadingData: false,
    diseaseListForm: false,
    disease_protection_list: [],
  };

  async componentDidMount() {
    const { getVaccinationHistoryById, routeParams } = this.props;
    const params = routeParams;
    if (params.id) {
      this.setState({ loadingData: true });
      try {
        const data = await getVaccinationHistoryById(params.id);
        const { disease_protection_list } = data;
        this.setState({
          ...data,
          disease_protection_list: disease_protection_list.map(
            ({ disease }) => disease,
          ),
        });
      } finally {
        this.setState({ loadingData: false });
      }
    }
  }

  onChange = (e) => {
    if (this.formLoading || this.props.detailMode) return;
    const { value, name } = e.target;
    this.setState({ [name]: value });
  };

  onSubmit = async (e) => {
    const {
      addVaccinationHistory,
      updateVaccinationHistory,
      // gotoDetailPath,
      basePath,
      history,
      setMsgInfo,
      tr,
    } = this.props;

    let { id, error, ...formData } = this.state;

    if (this.formLoading) return;
    formData = excludeUnsetField({ ...formData });
    const { disease_protection_list = [] } = formData;
    this.setState({ submittingForm: true, error: '' });
    let data = {};
    try {
      if (isEmptyValue(id)) {
        data = await addVaccinationHistory(formData);
        setMsgInfo({
          success: true,
          msg: [`${tr('Vaccine History Disease added successfully')}`],
        });
        
      } else {
        data = await updateVaccinationHistory(id, formData);
        setMsgInfo({
          success: true,
          msg: [`${tr('Vaccine History Disease updated successfully')}`],
        });
        
      }
      await this.setState({ ...data, disease_protection_list });
      // gotoDetailPath(data.id);
      history.push(basePath);
      window.scrollTo(0, 0);
    } finally {
      this.setState({ submittingForm: false });
    }
  };

  get formLoading() {
    const { submittingForm, loadingData } = this.state;
    return submittingForm || loadingData;
  }

  remove = (value) => {
    if (!value) return;
    const values = this.state.disease_protection_list.filter((v) => v !== value);
    this.onChange({
      target: {
        name: 'disease_protection_list',
        value: values,
      },
    });
  };

  toggleDiseaseListForm = () => {
    const { diseaseListForm } = this.state;
    if (this.formLoading || this.props.detailMode) return;
    this.setState({ diseaseListForm: !diseaseListForm });
  };

  get diseaseTypesLocaleOptions() {
    const { diseaseTypesLocaleOptions } = this.props;
    return diseaseTypesLocaleOptions.filter(({ vaccination }) => {
      return vaccination === true;
    });
  }

  render() {
    const {
      state,
      props,
      remove,
      toggleDiseaseListForm,
      diseaseTypesLocaleOptions,
    } = this;

    const { tr, trObj, basePath, orgFunctionAssignment } = props;

    return (
      <Wrapper className="form-wrapper">
      <div className="flex">
        <StyledButtonLink
          to={basePath}
          className="option-btn"
          icon="angle double left"
          name="All Entries"
        />
      </div>
      <div className="row">
        <div
          className="col-7 ml-10 mt-30 container"
          style={{
            position: 'relative',
            background: 'var(--admincat-color-grey-1)',
          }}
        >
          <br />
            <Form
              key={basePath}
              onSubmit={this.onSubmit}
              formData={state}
              validationSchema={vaccinationHistoryFormSchema}
              title={`Vaccination`}
              isLoading={state.submittingForm}
              loadingData={state.loadingData}
            >
              <InputDatePicker
                showRequiredAsterisk
                name="vaccination_date"
                label="Date"
                value={state.vaccination_date}
                onChange={this.onChange}
              />

              <ColumnalSelect
                optionDefaultValue='Choose Person'
                onChange={this.onChange}
                value={state.person}
                name="person"
                label="person"
                labels={personLabels}
                options={props.personalInfo.data}
              />

              <Select
                showRequiredAsterisk
                name="org"
                label="Organisation (Doctor)"
                value={state.org}
                optionDefaultValue={tr('Organisation')}
                onChange={this.onChange}
                options={orgFunctionAssignment.medical_facility_options}
              />

              <Input
                name="trade_name"
                label="Trade name (Vaccine)"
                value={state.trade_name}
                placeholder="Trade name"
                onChange={this.onChange}
              />

              <Input
                name="batch_no"
                label="Batch number (Vaccine)"
                value={state.batch_no}
                placeholder="Batch number"
                onChange={this.onChange}
              />
              <Divider />

              <DiseaseProtectionSection>
                <DisProtSection>
                  <FlexBox>
                    <span>{tr('Disease Protection')}</span>
                    <Icon name="plus" onClick={toggleDiseaseListForm} />
                  </FlexBox>
                </DisProtSection>
                {state.disease_protection_list.map((disease, index) => {
                  return (
                    disease && (
                      <Segment key={index}>
                        <div>
                          {trObj(
                            diseaseTypesLocaleOptions.find(
                              ({ id }) => id === disease,
                            ),
                          )}
                          <span>
                            <Icon name="cancel" onClick={() => remove(disease)} />
                          </span>
                        </div>
                      </Segment>
                    )
                  );
                })}
              </DiseaseProtectionSection>
              <Divider />
            </Form>

            <DiseaseProtectionList
              {...props}
              {...state}
              values={state}
              handleChange={this.onChange}
              diseaseProtectionList={diseaseTypesLocaleOptions}
              diseaseListForm={state.diseaseListForm}
              toggleDiseaseListForm={toggleDiseaseListForm}
            />
          </div>
        </div>
      </Wrapper>
    );
  }
}

const FlexBox = styled.div.attrs(() => ({
  className: 'FlexBox',
}))`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

const DisProtSection = styled.h5`
  margin: 0 !important;
`;

const DiseaseProtectionSection = styled.div.attrs(() => ({
  className: 'DiseaseProtectionSection',
}))`
  display: flex;
  margin-bottom: 1rem;
  flex-direction: column;
  // color: var(--admincat-color-grey-5);
`;

VaccinationHistoryForm.propTypes = {
  tr: PropTypes.func.isRequired,
};

export default compose(
  withCustomRouter,
  withOrgFunctionAssignment,
  withPersonalInfo,
  withTranslation,
  withDiseaseTypes,
  withVaccinationHistory,
  withRouter,
  withMsgInfo,
)(VaccinationHistoryForm);
