import React from 'react';
import styled from 'styled-components';
import compose from 'lodash/fp/compose';
import { useHistory } from 'react-router-dom';
import {
  withTranslation,
  withRelationPart,
  withPersonalInfo,
  withRelationInfo,
  withCustomRouter,
} from 'components/hoc';
import {
  Select,
  InputDatePicker,
  ColumnalSelect,
  Form,
  ButtonLink,
} from 'components/common/FormItems';
// import relationInfo from "reducers/dashboard/relationInfo";
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';

const personLabels = [
  {
    first_name: 'First Name',
  },
  {
    last_name: 'Last Name',
  },
];

const getRelationToOptions = (personId, personalInfo) => {
  return personalInfo.filter(({ id }) => +id !== +personId);
};

const Wrapper = styled.div``;

const StyledButtonLink = styled(ButtonLink)`
  span {
    text-transform: uppercase;
  }
`;

const relationFormSchema = {
  person: {
    validation: 'required',
  },
  relation_part: {
    validation: 'required',
  },
  relation_to: {
    validation: 'required',
  },
  valid_from: {
    validation: 'required',
  },
};

const RelationForm = (props) => {
  const [onChange, onSubmit, relationInfoFormData] = [
    props.onRelationInfoChange,
    props.onRelationInfoSubmit,
    props.relationInfoForm.data,
  ];
  const { id } = useParams();
  const relationList = useSelector((state) => state.relationInfo);
  const history = useHistory();

  React.useEffect(() => {
    if (id) {
      const relations = relationList.filter((relation) => relation.id == id);
      const relationDetailTemp = relations.length > 0 ? relations[0] : {};
      console.log('relationDetailTemp', relationDetailTemp);
      props.setDataToForm(relationDetailTemp);
    } else {
      props.setDataToForm({});
    }
  }, []);

  const [personValue, relationToValue] = [
    relationInfoFormData.person,
    relationInfoFormData.relation_to,
  ];

  const handleSubmit = (e) => {
    onSubmit(e);
    history.push(props.basePath);
  };

  // const handleSubmitForm = (e) => {
  //   handleSubmit(e);
  //   history.push(`/dashboard/documents`);
  // };

  // const handleChange = ({ target }) => {
  //   const { name, value } = target;
  //   const {
  //     setFormData,
  //     relationInfoForm: { data: relationInfoFormData },
  //   } = this.props;
  //   let data = { ...relationInfoFormData, [name]: value };

  //   // Making sure "person and relation_to fields" do not have same value. Because a person can
  //   // not be related to himself
  //   const { person, relation_to } = data;
  //   if ((person && relation_to) && (+person === +relation_to)) {
  //     data = { ...data, relation_to: '' };
  //   }
  //   setFormData({ data});
  // }

  // Making sure dropdown option shown on the relationTo field is not the
  // value selected on the person field
  const relationToOptions = getRelationToOptions(
    personValue,
    props.personalInfo.data,
  );

  const { basePath } = props;
  return (
    <Wrapper className="form-wrapper">
      <div className="flex">
        <StyledButtonLink
          to={basePath}
          className="option-btn"
          icon="angle double left"
          name="All Entries"
        />
      </div>
      <div className="row">
        <div
          className="col-7 ml-10 mt-30 container"
          style={{
            position: 'relative',
            background: 'var(--admincat-color-grey-1)',
          }}
        >
          <br/>
          <Form
            formData={relationInfoFormData}
            onSubmit={handleSubmit}
            validationSchema={relationFormSchema}
            isLoading={props.relationInfoFormSubmitting}
            title="Relation"
            label="Relation"
          >
            <ColumnalSelect
              optionDefaultValue="Choose Person"
              onChange={onChange}
              value={personValue || ''}
              name="person"
              label="Person"
              showRequiredAsterisk
              labels={personLabels}
              options={props.personalInfo.data}
            />
            <Select
              optionDefaultValue="Choose Relation Part"
              label="Relation Part"
              value={relationInfoFormData.relation_part || ''}
              name="relation_part"
              id="relation_part"
              localeOptions={props.relationPartLocaleOptions}
              onChange={onChange}
              showRequiredAsterisk
              sort={false}
            />
            <ColumnalSelect
              optionDefaultValue="Choose Relation"
              onChange={onChange}
              value={relationToValue || ''}
              name="relation_to"
              label="Relation To"
              showRequiredAsterisk
              labels={personLabels}
              options={relationToOptions}
              disabled={!personValue}
            />
            <InputDatePicker
              label="Valid From"
              value={relationInfoFormData.valid_from || ''}
              name="valid_from"
              onChange={onChange}
              showRequiredAsterisk
            />
            <InputDatePicker
              label="Valid To"
              value={relationInfoFormData.valid_to || ''}
              name="valid_to"
              onChange={onChange}
            />
          </Form>
        </div>
      </div>
    </Wrapper>
  );
};

export default compose(
  withTranslation,
  withRelationPart,
  withPersonalInfo,
  withRelationInfo,
  withCustomRouter,
)(RelationForm);
