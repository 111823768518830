import React from 'react';
import { withTranslation } from 'components/hoc';

export const SelectOptions = withTranslation(
  ({ langOptions, localeOptions, options, tr, trObj, sort, name }) => {
    localeOptions = localeOptions || langOptions;
    const func = localeOptions ? trObj : (d) => d.name;
    const optionData = localeOptions || options;
    return optionData
      .map((data) => {
        return {
          ...data,
          label: func(data),
        };
      })
      .sort((a, b) => {
        if (!sort) return 1;
        const labelA = a?.label?.toLowerCase();
        const labelB = b?.label?.toLowerCase();
        if (labelA) {
          return ('' + labelA).localeCompare(labelB);
        }
        return 1;
      })
      .map((data, index) => {
        return (
          <option key={index} value={data.id} disabled={data.disabled}>
            {data.label}
          </option>
        );
      });
  },
);

SelectOptions.defaultProps = {
  sort: true,
};

export const SelectOptionsCountry = ({ options, sort }) => {
  return options
    .map((data) => {
      return {
        ...data,
        label: `${data.tel_code}`, 
      };
    })
    .sort((a, b) => {
      if (!sort) return 1;
      const labelA = a?.label?.toLowerCase();
      const labelB = b?.label?.toLowerCase();
      if (labelA) {
        return ('' + labelA).localeCompare(labelB);
      }
      return 1;
    })
    .map((data, index) => {
      return (
        <option key={index} value={data.tel_code} disabled={data.disabled}>
          {data.label}
        </option>
      );
    });
};

SelectOptionsCountry.defaultProps = {
  sort: true,
};
