/* eslint-disable */
import React from 'react';
import compose from 'lodash/fp/compose';
import {
  withTranslation,
  withCustomRouter,
  withUserPreferences,
} from 'components/hoc';
import MyDataContentTemplate from 'components/Dashboard/MyData/common/MyDataContentTemplate';
import LottieLoader from 'components/common/LottieLoader';
import { withAutoRegistrationInfo } from 'components/hoc/AutoRegistration';

const AutoRegistration = (props) => {
  const mapFields = () => {
    const { autoRegistrationInfo, trObj, fd } = props;

    return autoRegistrationInfo.data.map((data) => {
      return {
        ...data,
        valid_from: fd(data?.valid_from),
        valid_to: fd(data?.valid_to),
        auto_mobile: trObj(data?.auto_mobile_detail?.auto_brand_detail),
        person:
          data?.person_detail?.first_name + ' ' + data?.person_detail?.last_name,
        registration_org: data?.registration_org_detail?.name,
        document: data?.document_detail?.description,
        valid_from: fd(data.valid_from),
        valid_to: fd(data.valid_to),
      };
    });
  };

  const { loaded, deleteBulkAutoRegistrationInfo, gotoCreatePath } = props;

  // Add filterableColumns configuration
  const filterableColumns = [
    {
      columnId: '_resident',
      columnDataType: 'text',
    },
    {
      columnId: 'auto_mobile',
      columnDataType: 'text',
    },
    {
      columnId: 'person',
      columnDataType: 'text',
    },
    {
      columnId: 'registration_org',
      columnDataType: 'text',
    },
    {
      columnId: 'licence_plate',
      columnDataType: 'text',
    },
    {
      columnId: 'document',
      columnDataType: 'text',
    },
    {
      columnId: 'valid_from',
      columnDataType: 'date',
    },
    {
      columnId: 'valid_to',
      columnDataType: 'date',
    },
  ];

  if (!loaded) {
    return (
      <>
        <LottieLoader />
      </>
    );
  }

  return (
    <MyDataContentTemplate
      tableName="auto_registration"
      columns={[]}
      data={mapFields()}
      handleCreateForm={gotoCreatePath}
      bulkDelete={deleteBulkAutoRegistrationInfo}
      filterableColumns={filterableColumns}
    />
  );
};

export default compose(
  withTranslation,
  withAutoRegistrationInfo,
  withCustomRouter,
  withUserPreferences,
)(AutoRegistration);
