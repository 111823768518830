import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import GlobalStyles from './styles/GlobalStyles';
import styled from 'styled-components';
import Header from './components/Header';
import MsgInfo from './components/MsgInfo';
import Footer from './components/Footer';
import Routes from './Routes';
import { ErrorBoundary, withAccount } from './components/hoc';
import 'semantic-ui-css/semantic.min.css';
import './static/assets/css/dashboard.css';
import './static/assets/css/tab.css';
import './static/workspace/css/main.css';
import './static/workspace/css/style.css';
import './static/workspace/css/maine.css';
import AppStyle from './App.css';
import commonStyle from './common.css';
import { DarkModeProvider } from './context/DarkModeContext';
import { useApiError } from './hooks/useApiError';
import FloatingDocumentUplaodLoader from 'components/Dashboard/Documents/Components/FloatingLoader';
import { Provider } from 'react-redux';

export const mobileBreakpoint = '800px';

const Wrapper = styled.div`
  ${AppStyle}
  ${commonStyle}
  div.container {
    padding: 0;
    max-width: 1200px;
    width: 95%;
  }
`;

const Main = styled.div.attrs(() => ({
  className: 'main',
}))``;

const App = (props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [isResolvingUser, setIsResolvingUser] = useState(true);
  const { error, handleApiError, clearError } = useApiError();

  useEffect(() => {
    setIsResolvingUser(true);
    try {
      const storedUser = localStorage.getItem('user');
      const token = localStorage.getItem('token');
      if (storedUser && token) {
        setIsAuthenticated(true);
        setUser(JSON.parse(storedUser));
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setIsResolvingUser(false);
    }
  }, [handleApiError]);

  // useEffect(() => {
  //   console.log('this is the root of the app');
  //   console.log({ upload_status });
  //   console.log({ task_id });
  // }, []);

  // useEffect(() => {
  //   setIsResolvingUser(true);
  //   const storedUser = localStorage.getItem('user');
  //   const token = localStorage.getItem('token');
  //   if (storedUser && token) {
  //     setIsAuthenticated(true);
  //     setUser(JSON.parse(storedUser));
  //   }
  //   setIsResolvingUser(false);
  // }, []);

  return (
    <DarkModeProvider>
      <Wrapper>
        <Provider store={props.store}>
          <ErrorBoundary>
            <GlobalStyles />
            <BrowserRouter>
              <Main className={isAuthenticated ? 'authenticated' : ''}>
                <Header />
                <MsgInfo />
                {/* <FloatingDocumentUplaodLoader /> */}
                {error && (
                  <MsgInfo
                    message={error.message}
                    type="error"
                    onClose={clearError}
                  />
                )}
                <Routes
                  user={user}
                  isAuthenticated={isAuthenticated}
                  isResolvingUser={isResolvingUser}
                />
              </Main>
              <Footer />
            </BrowserRouter>
          </ErrorBoundary>
        </Provider>
      </Wrapper>
    </DarkModeProvider>
  );
};

export default App;
