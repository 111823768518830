import React from 'react';
import { Form as BaseForm, withFormProps } from '../common/Form';
import { Input, Select, NewEntriesSelect } from 'components/common/FormItems';
import { MultiColumns } from '../styles';
import { RESPONSE_STRINGS } from '../../../../../constants';
import axios from '../../../../../axios';
import API from '../../../../../actions/api';
import swal from 'sweetalert';
import { getResError } from '../../../../../actions/account';

class Form extends BaseForm {
  constructor(props) {
    super(props);
    this.state = {
      showEntryForm: false,
      entryValues: { value: '', language: this.userPreferencesOptions.language },
      entrySubmitting: false,
    };
  }

  educationFormSchema = {
    organisation: this.required,
  };

  toggleEntryField = () => {
    this.setState({ showEntryForm: !this.state.showEntryForm });
  };

  onEntryChange = (e) => {
    const { value, name } = e.target;
    this.setState({
      entryValues: {
        ...this.state.entryValues,
        [name]: value,
      },
    });
  };

  validateAndSubmitEntry = async (entryType) => {
    const { name, language } = this.state.entryValues;
    if (!(name && language)) {
      swal(
        this.props.tr('Warning!'),
        this.props.tr(`Please ensure all required fields are populated.`),
        'warning',
      );
      return;
    }

    try {
      this.setState({ entrySubmitting: true });
      const payload = {
        name,
        language,
        entry_type: entryType,
      };
      const response = await axios.post(`${API}/propose/entry/`, payload);
      swal(
        this.props.tr('Success!'),
        this.props.tr(`${RESPONSE_STRINGS?.ENTRY_RESPONSE_SUCCESS_MESSAGE}`),
        'success',
      );
      this.setState({
        entryValues: { value: '', language: this.userPreferencesOptions.language },
        entrySubmitting: false,
        showEntryForm: false,
      });
    } catch (error) {
      const err = getResError(error);
      this.setState({ entrySubmitting: false, showEntryForm: false });
      this.props.setMsgInfo({ success: false, msg: err });
    }
  };

  renderUpperInputs = (state) => {
    return (
      <>
        <Select
          onChange={this.onChange}
          value={state.organisation}
          name="organisation"
          label="School"
          optionDefaultValue="Choose Organisation"
          options={this.organisationOptions}
          showRequiredAsterisk
        />
        <Select
          onChange={this.onChange}
          value={this.getSchoolTypeValue(state.organisation)}
          name="school_type"
          label="School Type"
          optionDefaultValue="Choose School Type"
          options={this.schoolTypeOptions}
          disabled
        />
      </>
    );
  };

  renderLowerInputs = (state) => {
    return (
      <>
        <Select
          onChange={this.onChange}
          value={state.graduation_goal}
          name="graduation_goal"
          label="Aspired School Graduation (if any)"
          optionDefaultValue="Aspired school graduation"
          options={this.schoolGraduationOptions}
        />
        <NewEntriesSelect
          value={state.school_subject || ''}
          name="school_subject"
          label={this.props.tr('Subject Area (if any)')}
          optionDefaultValue={this.props.tr('Subject area')}
          localeOptions={this.props.schoolSubject}
          onChange={(e) => {
            if (e.target.value == 'proposeValue') {
              this.toggleEntryField();
              return;
            } else {
              this.onChange(e);
            }
          }}
          entryDefaultLanguage={this.state.entryValues.language}
          entryValue={this.state.entryValues.name}
          entryLanguageValue={this.state.entryValues.language}
          entryValueName={this.props.tr('School Subject')}
          entryType={this.props.tr('School Subject')}
          entryLanguageName={'language'}
          onEntryChange={this.onEntryChange}
          onCreateNewEntry={(e) => {
            e.preventDefault();
            this.validateAndSubmitEntry('subject_area');
          }}
          add={this.state.showEntryForm}
          toggleEntryField={this.toggleEntryField}
          options={this.activeLanguageOptions}
          isLoading={this.state.entrySubmitting}
          closeEntryForm={() => {
            this.toggleEntryField();
          }}
        />
        <MultiColumns col={3}>
          <Select
            onChange={this.onChange}
            value={state.degree_achieval}
            name="degree_achieval"
            label="Degree achieval"
            optionDefaultValue="Degree achieval"
            localeOptions={this.degreeOptions}
            sort={false}
          />
          <Input
            onChange={this.onChange}
            value={state.final_grade}
            name="final_grade"
            label="Final grade"
          />
          <Select
            onChange={this.onChange}
            value={state.last_class}
            name="last_class"
            label="Last class"
            optionDefaultValue="Last class"
            localeOptions={this.lastClassOptions}
            sort={false}
          />
        </MultiColumns>
      </>
    );
  };
}

export default withFormProps(Form);
