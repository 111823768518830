import React from 'react';
import compose from 'lodash/fp/compose';
import {
  withTranslation,
  withDateFormat,
  withUserPreferences,
  withBankAccount,
  withCustomRouter,
} from 'components/hoc';
import MyDataContentTemplate from '../../common/MyDataContentTemplate';
import LottieLoader from 'components/common/LottieLoader';
import { isActive } from './utils';
import { useDispatch, useSelector } from 'react-redux';
import { bankTransactionExtraction } from 'actions';

const renderIsActiveIcon = (valid_from, valid_to) => {
  return isActive(valid_from, valid_to) ? '\u2713' : '\u2717';
};

const Finance = (props) => {
  const filterableColumns = [
    {
      columnId: '_account_type',
      columnDataType: 'text',
    },
    {
      columnId: 'account_no',
      columnDataType: 'text',
    },
    {
      columnId: '_BIC',
      columnDataType: 'text',
    },
    {
      columnId: '_valid_from',
      columnDataType: 'date',
    },
    {
      columnId: '_valid_to',
      columnDataType: 'date',
    },
    {
      columnId: 'currency',
      columnDataType: 'text',
    },
    {
      columnId: '_access_type',
      columnDataType: 'text',
    },
    {
      columnId: 'organisation',
      columnDataType: 'text',
    },
    {
      columnId: '_account_ref',
      columnDataType: 'text',
    },
    {
      columnId: 'iban',
      columnDataType: 'text',
    },
    {
      columnId: 'is_active',
      columnDataType: 'text',
    },
    {
      columnId: 'IBAN',
      columnDataType: 'text',
    },
    {
      columnId: 'space_name',
      columnDataType: 'text',
    },
  ];

  const { deleteBulkBankAccount, bankAccounts, fd, trObj, gotoCreatePath, loaded } =
    props;

  const dispatch = useDispatch();
  // const { loading, transactions, error } = useSelector((state) => state.bankTransactionExtract);

  // extract transactions when user triggers the action
  const handleBankExtractionTransactions = (bankId) => {
    dispatch(bankTransactionExtraction(bankId));
  };

  // Map the fields for displaying bank account data in the table
  const mapFields = () => {
    return bankAccounts.map((data) => ({
      ...data,
      space_name: data.space,
      is_active: renderIsActiveIcon(data.valid_from, data.valid_to),
      _valid_from: fd(data.valid_from),
      _valid_to: fd(data.valid_to),
      _access_type: trObj(data.account_access_type_detail),
      _account_type: trObj(data.account_type_detail),
      _account_ref: data.account_ref_detail?.account_no,
      _BIC: data.BIC_data?.BIC,
      organisation: data.BIC_data?.org_name,
    }));
  };

  // Show a loader while transactions are being fetched
  // if (!loaded || loading) {
  //   return <LottieLoader />;
  // }

  if (!loaded) {
    return <LottieLoader />;
  }

  // Handle error in transaction fetching
  // if (error) {
  //   return <div>Error: {error}</div>;
  // }

  return (
    <React.Fragment>
      <MyDataContentTemplate
        tableName="bank_account"
        columns={[]}
        data={mapFields()}
        handleCreateForm={gotoCreatePath}
        bulkDelete={deleteBulkBankAccount}
        loadBankTransaction
        bankTransaction={handleBankExtractionTransactions}
        filterableColumns={filterableColumns}
      />
    </React.Fragment>
  );
};

export default compose(
  withTranslation,
  withDateFormat,
  withUserPreferences,
  withBankAccount,
  withCustomRouter,
)(Finance);
