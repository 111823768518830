import axios from 'axios';
import setAuthToken from '../authToken';
import { AUTH } from './types';
import { setMsgInfo, createAction, createTypes, getUserPreferences } from './';
import API from './api';
import i18next from 'i18next';
import { v4 as uuidv4 } from 'uuid';

export function getResError(data) {
  let errors = [];

  if (data.response?.data.space && data.response.data.space.name) {
    errors = [...errors, data.response.data.space.name[0]];
  }

  if (data.response?.data.user && data.response.data.user.username) {
    errors = [...errors, ...data.response.data.user.username];
  }

  if (data.response.data.user && data.response?.data.user.email) {
    errors = [...errors, ...data.response.data.user.email];
  }

  if (data.response.data.user && data.response.data.user.password) {
    errors = [...errors, 'password: ' + data.response.data.user.password[0]];
  }

  if (data.response.data.errors && data.response.data.errors.username) {
    errors = [...errors, ...data.response.data.errors.username];
  }

  if (data.response.data.errors && data.response.data.errors.email) {
    errors = [...errors, ...data.response.data.errors.email];
  }

  if (data.response.data.errors && data.response.data.errors.password) {
    errors = [...errors, 'password: ' + data.response.data.errors.password[0]];
  }

  if (data.response.data.errors && data.response.data.errors.error) {
    errors = [...errors, data.response.data.errors.error[0]];
  }

  if (data.response.data.errors && data.response.data.errors.organisation) {
    errors = [...errors, data.response.data.errors.organisation[0]];
  }

  if (errors.length) {
    return errors;
  }

  let err = Object.values(data.response.data);
  if (Array.isArray(err)) {
    return err;
  }
  return [err];
}

export function setAuthUser(data = {}) {
  // saving user's data in the store
  return createAction(createTypes(AUTH).success, { user: data });
}

const authLoading = (isLoading = false) => {
  return createAction(createTypes(AUTH).loading, isLoading);
};

export function removeAuthUser() {
  setAuthToken();
  localStorage.removeItem('user');
  localStorage.removeItem('userPreferences');
  localStorage.removeItem('form_id');
}

export function logout(history) {
  return (dispatch) => {
    // removing token to request headers
    removeAuthUser();
    dispatch(setAuthUser());
  };
}

export function getLoginOtp(payload) {
  return async (dispatch) => {
    try {
      dispatch(authLoading(true));

      const {
        data: { user_email },
      } = await axios.post(API + '/login/otp/', payload);
    } catch (err) {
      dispatch(authLoading(false));
      const error = getResError(err);
      dispatch(setMsgInfo({ success: false, msg: error }));
    }
  };
}

export function resendOTP(payload) {
  return async (dispatch) => {
    try {
      dispatch(authLoading(true));
      await axios.post(API + '/otp/resend/', payload);
      dispatch(authLoading(false));
      dispatch(
        setMsgInfo({ success: true, msg: ['OTP Resent please check your mail.'] }),
      );
    } catch (err) {
      dispatch(authLoading(false));
      const error = getResError(err);
      dispatch(setMsgInfo({ success: false, msg: error }));
    }
  };
}

export function validateOTP(payload, history) {
  return async (dispatch) => {
    try {
      dispatch(authLoading(true));

      const {
        data: { user, token, refresh, otp_verification },
      } = await axios.post(API + '/otp/verify/', payload);

      // log user in normally
      setAuthToken(token);

      localStorage.setItem('refreshToken', refresh);
      localStorage.setItem('user', JSON.stringify(user));
      // deleteCookie("i18next")
      // localStorage.removeItem('i18nextLng');

      dispatch(setAuthUser({ ...user, isAuth: true, data: true }));
      await getUserPreferences()(dispatch);
      history.replace('/dashboard');
      window.location.reload();
      dispatch(authLoading(false));
    } catch (err) {
      dispatch(authLoading(false));
      const error = getResError(err);
      dispatch(setMsgInfo({ success: false, msg: error }));
    }
  };
}

export function login(payload, history, redirectPath) {
  return async (dispatch) => {
    try {
      dispatch(authLoading(true));

      const {
        data: { user, token, refresh, otp_verification, otp_browser_dependent },
      } = await axios.post(API + '/login/', payload);

      if (otp_browser_dependent && !localStorage.getItem('browserId')) {
        localStorage.setItem('browserId', uuidv4());
      } else if (!otp_browser_dependent) {
        localStorage.removeItem('browserId');
      }

      if (otp_verification) {
        // if otp is required
        // implement otp authentication
        localStorage.setItem('otp_email', user.email);
        localStorage.setItem('user_id', user.user_id);
        dispatch(authLoading(false));
        history.push('/otp');
      } else {
        // if otp is not required
        // log user in normally
        setAuthToken(token);

        localStorage.setItem('refreshToken', refresh);
        localStorage.setItem('user', JSON.stringify(user));
        // deleteCookie("i18next")
        // localStorage.removeItem('i18nextLng');

        dispatch(setAuthUser({ ...user, isAuth: true, data: true }));
        await getUserPreferences()(dispatch);
        if (redirectPath) {
          history.push(redirectPath);
        } else {
          history.push('/dashboard');
          // history.replace('/dashboard/home');
        }
        window.location.reload();
      }
    } catch (err) {
      dispatch(authLoading(false));
      const error = getResError(err);
      dispatch(setMsgInfo({ success: false, msg: error }));
    }
  };
}

export function adminLogin(payload, history, redirectPath) {
  return async (dispatch) => {
    try {
      dispatch(authLoading(true));

      const {
        data: { user, token, refresh, otp_verification, otp_browser_dependent },
      } = await axios.post(API + `/login/?admin=${true}`, payload);

      if (otp_browser_dependent && !localStorage.getItem('browserId')) {
        localStorage.setItem('browserId', uuidv4());
      } else if (!otp_browser_dependent) {
        localStorage.removeItem('browserId');
      }
      setAuthToken(token);
      localStorage.setItem('refreshToken', refresh);
      localStorage.setItem('user', JSON.stringify(user));

      dispatch(setAuthUser({ ...user, isAuth: true, data: true }));
      await getUserPreferences()(dispatch);

      window.location.reload();
      if (redirectPath) {
        history.push(redirectPath);
      } else {
        history.push('/admin/dashboard'); // move to the admin dashboard
      }
      window.location.reload();
    } catch (err) {
      dispatch(authLoading(false));
      const error = getResError(err);
      dispatch(setMsgInfo({ success: false, msg: error }));
    }
  };
}

const baseSignup = (path, payload) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(API + path, payload);
      dispatch(
        setMsgInfo({
          success: true,
          msg: [i18next.t('Registration successful')],
        }),
      );
      return data;
    } catch (err) {
      const error = getResError(err);
      dispatch(
        setMsgInfo({
          success: false,
          msg: error,
        }),
      );
      throw err;
    }
  };
};

export function createOrgSignup(payload) {
  return baseSignup('/signup/create/organisation/', payload);
}

export function createPersonalSignup(payload) {
  return baseSignup('/signup/create/personal/', payload);
}

export function joinOrgSignup(name, payload) {
  return baseSignup(`/signup/join/organisation/${name}/`, payload);
}

export function joinPersonalSignup(name, payload) {
  return baseSignup(`/signup/join/personal/${name}/`, payload);
}

export function passwordReset(data) {
  return (dispatch) => {
    return axios
      .post(API + '/password/reset/', data)
      .then((data) => {
        dispatch(
          setMsgInfo({
            success: true,
            msg: ['Check your email for password reset link'],
          }),
        );
      })
      .catch((err) => {
        const error = getResError(err);
        dispatch(setMsgInfo({ success: false, msg: error }));
        throw err;
      });
  };
}

export function verifyEmail(data) {
  const URI = decodeURI(API + '/signup/verify/' + data);
  return (dispatch) => {
    return axios
      .get(URI)
      .then((data) => {
        dispatch(
          setMsgInfo({
            success: true,
            msg: ['User verified!'],
          }),
        );
      })
      .catch((err) => {
        const error = getResError(err);
        dispatch(setMsgInfo({ success: false, msg: error }));
        throw err;
      });
  };
}

export function verifyChangePasswordLink(data) {
  return (dispatch) => {
    return axios
      .get(API + '/password/reset/verify/' + data)
      .then((data) => {
        dispatch(
          setMsgInfo({
            success: true,
            msg: ['User verified!'],
          }),
        );
      })
      .catch((err) => {
        const error = getResError(err);
        dispatch(setMsgInfo({ success: false, msg: error }));
        throw err;
      });
  };
}

export function verifyPasswordChange(data) {
  return (dispatch) => {
    return axios
      .post(API + '/password/reset/verified/', data)
      .then((data) => {
        dispatch(
          setMsgInfo({
            success: true,
            msg: ['Password changed successfully'],
          }),
        );
        setInterval(function () {
          window.location = '/login';
        }, 2500);
      })
      .catch((err) => {
        const error = getResError(err);
        dispatch(setMsgInfo({ success: false, msg: error }));
        throw err;
      });
  };
}

export function passwordChange(data) {
  return (dispatch) => {
    return axios
      .put(API + '/password/change/new/', data)
      .then((data) => {
        dispatch(
          setMsgInfo({
            success: true,
            msg: ['Password updated successfully'],
          }),
        );
      })
      .catch((err) => {
        const error = getResError(err);
        dispatch(setMsgInfo({ success: false, msg: error }));
        throw err;
      });
  };
}
