/* eslint-disable */
import React from 'react';
import compose from 'lodash/fp/compose';
import { useSelector } from 'react-redux';
import {
  withTranslation,
  withUserPreferences,
  withCustomRouter,
  withUserDefaultSpace,
} from 'components/hoc';
import TransactionContentTemplate from '../../common/TransactionContentTemplate';
// import MyDataContentTemplate from '../../common/MyDataContentTemplate';
import { useListTransaction } from '../hooks/useListTransaction';
import { getFullName } from 'components/utils';
import { getTransactionItem, getDCIndicator } from '../utils';
import LottieLoader from 'components/common/LottieLoader';

const Income = (props) => {
  const loading = useSelector((state) => state.transaction.loading);
  const { data, refreshTransactions } = useListTransaction('income');
  const { fn, fd, userDefaultSpace, trObj } = props;

  // Add filterableColumns configuration
  const filterableColumns = [
    {
      columnId: 'person',
      columnDataType: 'text',
    },
    {
      columnId: 'description',
      columnDataType: 'text',
    },
    {
      columnId: 'date',
      columnDataType: 'date',
    },
    {
      columnId: 'partner',
      columnDataType: 'text',
    },
    {
      columnId: 'amount',
      columnDataType: 'number',
    },
    {
      columnId: 'amount_sc',
      columnDataType: 'number',
    },
    {
      columnId: 'currency',
      columnDataType: 'text',
    },
    {
      columnId: 'contract',
      columnDataType: 'text',
    },
    {
      columnId: 'dc_indicator',
      columnDataType: 'text',
    },
    {
      columnId: 'org_indicator',
      columnDataType: 'text',
    },
    {
      columnId: 'account_category',
      columnDataType: 'text',
    },
    {
      columnId: 'financial_account',
      columnDataType: 'text',
    },
    {
      columnId: 'offset_account_category',
      columnDataType: 'text',
    },
    {
      columnId: 'offset_account',
      columnDataType: 'text',
    },
    {
      columnId: 'reversal',
      columnDataType: 'text',
    },
    {
      columnId: 'reversed',
      columnDataType: 'text',
    },
    {
      columnId: 'reversal_doc',
      columnDataType: 'text',
    },
    {
      columnId: 'original_doc',
      columnDataType: 'text',
    },
    {
      columnId: 'year',
      columnDataType: 'number',
    },
    {
      columnId: 'period',
      columnDataType: 'text',
    },
    {
      columnId: 'measurement_unit',
      columnDataType: 'text',
    },
    {
      columnId: 'quantity',
      columnDataType: 'number',
    },
    {
      columnId: 'line',
      columnDataType: 'number',
    },
    {
      columnId: 'position_link',
      columnDataType: 'text',
    },
    {
      columnId: 'sp_currency',
      columnDataType: 'text',
    },
    {
      columnId: 'transaction',
      columnDataType: 'number',
    },
    {
      columnId: 'reverse_doc_no',
      columnDataType: 'number',
    },
    {
      columnId: 'original_doc_no',
      columnDataType: 'number',
    },
  ];


  const mapFields = () => {
    return data?.map((income) => {
      const transactionItem = getTransactionItem(income.transaction_item[0], fn);
      return {
        ...income,
        ...transactionItem,
        space_name: income.space,
        person: getFullName(income.transaction_item[1].person_details),
        description: income.description,
        date: fd(income.date),
        partner: income?.partner_detail?.name,
        amount: fn(income.transaction_item[1].amount),
        amount_sc: fn(income.transaction_item[1].amount_sc),
        sp_currency: userDefaultSpace.space_detail.currency,
        currency: transactionItem.currency,
        contract: income.contract_details?.description,
        dc_indicator: getDCIndicator(transactionItem.dc_indicator),
        org_indicator: income.org_indicator,
        account_category: props.trObj(
          income.transaction_item[1].account_category_details,
        ),
        financial_account: props.trObj(
          income.transaction_item[1].financial_account_details,
        ),
        offset_account_category: props.trObj(
          income.transaction_item[0].account_category_details,
        ),
        offset_account: props.trObj(
          income.transaction_item[0].financial_account_details,
        ),
        reversal: income.reversal ? '\u2713' : '\u2717',
        reversed: income.reversed ? '\u2713' : '\u2717',
        measurement_unit: trObj(transactionItem.measurement_unit_detail),
        reverse_doc_no: income.reverse_doc_no,
        original_doc_no: income.original_doc_no,
      };
    });
  };


  const { gotoCreatePath } = props;

  const tableProps = {
    frontendTableName: 'income',
    backendTableName: 'transaction',
  };

  if (loading)
    return (
      <>
        <LottieLoader />
      </>
    );

  return (
    <TransactionContentTemplate
      {...tableProps}
      columns={[]}
      data={mapFields() || []}
      toggleForm={gotoCreatePath}
      hasBulkDelete={false}
      refreshTransactions={refreshTransactions}
      filterableColumns={filterableColumns}
    />
  );
};

export default compose(
  withTranslation,
  withUserPreferences,
  withCustomRouter,
  withUserDefaultSpace,
)(Income);
